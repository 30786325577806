<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      max-height="800px"
      scrollable
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-else>
        <v-card-title
          class="py-0 pt-7"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img src="@/assets/teamModalLogo.svg" height="85" width="100" />
          </div>
          <div class="ml-1 dialogTitle-text">
            {{ dialogTitle }}
          </div>
          <v-spacer></v-spacer>
          <v-icon
            text
            large
            color="white"
            style="
              cursor: pointer;
              position: relative;
              right: -15px;
              bottom: 40px;
            "
            @click="toggleVolunteerModal({ show: false })"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-5">
          <v-form lazy-validation ref="volunteerForm">
            <v-row no-gutters justify="center">
              <v-col cols="11">
                <v-text-field
                  outlined
                  dense
                  v-model="name"
                  label="Name"
                  color="#7253CF"
                  class="formFields"
                  :rules="[rules.required]"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="11">
                <v-text-field
                  outlined
                  dense
                  v-model="email"
                  label="Email"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                  :rules="[rules.required, rules.email]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="11">
                <v-text-field
                  outlined
                  dense
                  v-model="mobile"
                  label="Mobile Number"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="11">
                <v-text-field
                  outlined
                  dense
                  v-model="areaOfFocus"
                  label="Designation"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <!--   <v-col cols="11">
                <v-autocomplete
                  label="Area of Focus"
                  outlined
                  dense
                  :rules="[rules.required]"
                  v-model="areaOfFocus"
                  :items="areaOfFocusList"
                  class="formFields"
                  item-text="name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-autocomplete>
              </v-col>-->
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            class="dialogAction-btnText text-capitalize px-7"
            height="45"
            color="#38227A"
            :loading="formLoading"
            @click="submitForm"
          >
            <span>{{ actionText }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Axios from "@/api/BaseAxios";
import {
  AREA_OF_FOCUS_CATEGORIES,
  VOLUNTEER_API_POST,
  API_GAME_COMMITTEE_POST,
  VOLUNTEER_API_PATCH,
  VOLUNTEER_API_GET,
  API_GAME_COMMITTEE_GET_DETAIL,
} from "@/constants/APIUrls";
import RULES from "@/common/fieldRules";
export default {
  name: "VolunteerModal",
  data() {
    return {
      rules: RULES,
      loading: false,
      formLoading: false,
      name: "",
      email: "",
      mobile: null,
      areaOfFocus: null,
      areaOfFocusList: [],
    };
  },
  computed: {
    ...mapGetters({
      showGetters: "gameManagement/getVolunteer",
      selectGameData: "gameManagement/getGame",
    }),
    show: {
      get() {
        return this.showGetters;
      },
      set(value) {
        return this.toggleVolunteerModal({ show: value });
      },
    },
    type() {
      return this.$store.state.gameManagement.volunteerModal.type;
    },
    dialogTitle() {
      if (this.type === "add") return "Add new Committee";
      else return "Update Committee detail";
    },
    actionText() {
      if (this.type === "add") {
        return "Add Committee";
      } else {
        return "Save";
      }
    },
    toastMessage() {
      if (this.type === "add") {
        return "Volunteer Added Successfully.";
      } else {
        return "Volunteer Detail Updated.";
      }
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleVolunteerModal: "gameManagement/toggleVolunteer",
      showToast: "snackBar/showToast",
    }),
    openModal() {
      this.getAreaOfFoucsList();
    },
    closeModal() {
      this.loading = false;
      this.formLoading = false;
      this.name = "";
      this.email = "";
      this.mobile = null;
      this.areaOfFocus = null;
      this.areaOfFocusList = [];
    },
    getAreaOfFoucsList() {
      this.loading = true;
      const successHandler = (res) => {
        console.log(res);
        this.areaOfFocusList = res.data.category_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {
        if (this.type === "add") {
          this.loading = false;
        } else {
          this.getDetail();
        }
      };
      let formData = {};
      Axios.request_GET(
        AREA_OF_FOCUS_CATEGORIES,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    getDetail1() {
      const successHandler = (res) => {
        this.name = res.data.volunteer_detail.name;
        this.email = res.data.volunteer_detail.email;
        this.mobile = res.data.volunteer_detail.phone;
        this.areaOfFocus = res.data.volunteer_detail.area_of_focus;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["volunteer_id"] =
        this.$store.state.gameManagement.volunteerModal.volunteerID;
      Axios.request_GET(
        VOLUNTEER_API_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getDetail() {
      const successHandler = (res) => {
        this.name = res.data.team_detail.name;
        this.email = res.data.team_detail.email;
        this.mobile = res.data.team_detail.phone;
        this.areaOfFocus = res.data.team_detail.game_designation;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["user_id"] =
        this.$store.state.gameManagement.volunteerModal.volunteerID;
      Axios.request_GET(
        API_GAME_COMMITTEE_GET_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitForm1() {
      if (this.$refs.volunteerForm.validate()) {
        this.formLoading = true;
        const successHandler = (res) => {
          console.log(res);
          this.formLoading = false;
          this.showToast({
            message: this.toastMessage,
            color: "s",
          });
          this.$emit("reload");
          this.toggleVolunteerModal({ show: false });
        };
        const failureHandler = (res) => {
          console.log(res);
          this.formLoading = false;
          this.showToast({
            message: res,
            color: "e",
          });
        };
        let formData = {};
        formData["game"] = this.$route.query.game_id;
        console.log(formData, this.selectGameData);
        formData["name"] = this.name;
        if (this.mobile) {
          formData["phone"] = this.mobile;
        }
        formData["email"] = this.email;
        formData["area_of_focus"] = this.areaOfFocus;
        if (this.type === "add") {
          Axios.request_POST(
            VOLUNTEER_API_POST,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        } else {
          formData["id"] =
            this.$store.state.gameManagement.volunteerModal.volunteerID;
          Axios.request_PATCH(
            VOLUNTEER_API_PATCH,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        }
      }
    },
    submitForm() {
      if (this.$refs.volunteerForm.validate()) {
        this.formLoading = true;
        const successHandler = (res) => {
          console.log(res);
          this.formLoading = false;
          this.showToast({
            message: this.toastMessage,
            color: "s",
          });
          this.$emit("reload");
          this.toggleVolunteerModal({ show: false });
        };
        const failureHandler = (res) => {
          console.log(res);
          this.formLoading = false;
          this.showToast({
            message: res,
            color: "e",
          });
        };
        let formData = {};
        formData["game_id"] = this.$route.query.game_id;
        console.log(formData, this.selectGameData);
        formData["user_name"] = this.name;
        if (this.mobile) {
          formData["user_phone"] = this.mobile;
        }
        formData["user_email"] = this.email;
        formData["designation"] = this.areaOfFocus;
        if (this.type === "add") {
          Axios.request_POST(
            API_GAME_COMMITTEE_POST,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        } else {
          formData["id"] =
            this.$store.state.gameManagement.volunteerModal.volunteerID;
          Axios.request_PATCH(
            VOLUNTEER_API_PATCH,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        }
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.v-btn.dialogAction-btnText {
  border-radius: 10px;
}
.v-btn >>> span {
  letter-spacing: 0px;
}
</style>
